<template>
  <el-dialog
    :visible.sync="tasksFilterVisible"
    width="80%"
    :before-close="closeTasksFilter"
  >
    <OopTable
      ref="oopTable"
      :repository="repository"
      :columns="columns"
      no-auto-fetch
    >
      <template #level0-cell="scope">
        <div v-if="scope.columnIndex === 0">
          <el-checkbox
            :value="!!chosenTasks.find(t => t.uuid === scope.row.uuid)"
            @change="checkTask($event, scope.row)"
          />
        </div>
        <div v-if="scope.columnIndex === 1">
          {{ scope.row.description }}
        </div>
        <div v-if="scope.columnIndex === 2">
          {{ scope.row.project.name }}
        </div>
        <div v-if="scope.columnIndex === 3">
          {{ scope.row.user.name }}
        </div>
      </template>
    </OopTable>
    <div style="display: flex; justify-content: flex-end">
      <el-button
        plain
        @click.prevent="closeTasksFilter"
      >
        Cancel
      </el-button>
      <el-button
        type="success"
        title="Click to save"
        @click.prevent="passTasks"
      >
        Add
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    tasksFilterVisible: {
      type: Boolean,
      require: false,
    },
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
    repository: {
      type: Object,
      require: false,
      default: () => null,
    },
  },
  data() {
    return {
      chosenTasks: [],
      visible: false,
      isChecked: false,
      columns: {
        checkbox: {
          title: ' ',
          width: '50px',
        },
        description: {
          title: 'Description',
          width: '3fr',
        },
        project: {
          title: 'Project',
          width: '2fr',
        },
        user: {
          title: 'User',
          width: '2fr',
        },
      },
    };
  },
  computed: {
    tasks() {
      return this.taskRepository.dataset;
    },
  },
  methods: {
    closeTasksFilter() {
      this.$emit('close-tasks-filter');
    },
    passTasks() {
      this.$emit('pass-tasks', this.chosenTasks);
      this.chosenTasks = [];
      this.closeTasksFilter();
    },
    checkTask(value, task) {
      if (value) {
        this.chosenTasks.push(task);
      } else {
        this.chosenTasks = this.chosenTasks.filter(t => t.uuid !== task.uuid);
      }
    },
  },
};
</script>
